<template>
  <div class="invoices">

    <default-invoice-page/>

  </div>
</template>

<script>

import DefaultInvoicePage from "@/components/default_invoice_page";

export default {
  components: {DefaultInvoicePage}
}
</script>