import {getPathPrefix, getRecord, getRecords, postRecord, putRecord, removeRecord} from "@/services/api";

export function getDrivers(filterQuery, options = {}) {
    filterQuery.datatable = true
    if (options.toggleProgressSpinner !== true) options.toggleProgressSpinner = false
    return getRecords('/prologistics/web/api/v1/' + getPathPrefix() + 'drivers.json', filterQuery, options)
}

export function getDriver(id, filterQuery = {}, options = {}) {
    return getRecord('/prologistics/web/api/v1/' + getPathPrefix() + 'drivers/' + id + '.json', filterQuery, options)
}

export function postDriver(data, options = {}) {
    return postRecord('/prologistics/web/api/v1/' + getPathPrefix() + 'drivers.json', data, options)
}

export function putDriver(id, data, options = {}) {
    return putRecord('/prologistics/web/api/v1/' + getPathPrefix() + 'drivers/' + id + '.json', data, options)
}

export function removeDriver(id, options = {}) {
    return removeRecord('/prologistics/web/api/v1/' + getPathPrefix() + 'drivers/', id, options)
}

export function getSelectableDrivers(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/web/api/v1/' + getPathPrefix() + 'drivers.json', filterQuery, options)
}

export function updatePasswordByResetToken(token, password, password_confirmation) {
    return putRecord("/prologistics/api/v1/auth/password.json", {
        reset_password_token: token,
        password: password,
        password_confirmation: password_confirmation
    })
}