<template>
  <div class="default-invoice-page">

    <b-row class="m-b-sm">
      <b-col md>
        <!-- not empty -->
        <div class="ibox-content no-borders b-r-lg">

          <!-- title -->
          <b-row>
            <div class="article m">
              <h1>{{ $t(title) }}</h1>
              <p>{{ $t(body) }}</p>
              <b-form-group label-for="input-filter">
                <template slot="label">
                  {{ $t('invoices.show_invoices_for') }}
                </template>
                <searchable-select v-if="showRentalClientSelector" class="m-b-sm" id="input-filter"
                                   v-model="clientFilterId" :fetchRecords="clientFilterOptions"
                                   :placeholder="$t('selector.select_all_principals')"/>
                <searchable-select v-if="showDriverSelector" class="m-b-sm" id="input-filter" v-model="driverFilterId"
                                   :fetchRecords="driverFilterOptions"
                                   :placeholder="$t('selector.select_all_drivers')"/>
                <searchable-select id="input-filter" v-model="timeFilterId" :fetchRecords="timeFilterOptions"/>
              </b-form-group>
            </div>
          </b-row>
        </div>
      </b-col>

      <b-col md>
        <div class="ibox-title">
          <h5>{{ $t('invoices.list_of_invoices') }}</h5>
        </div>
        <!-- general -->
        <div class="ibox-content">
          <!-- table -->
          <datatable :columns="columns" :fetchRecords="fetchRecords" :actions="actions" :searchEnabled="false"
                     :emptyText="$t('invoices.empty_label')" thClass="text-center" tdClass="text-center"/>
        </div>
      </b-col>

    </b-row>

  </div>
</template>

<script>

import SearchableSelect from "@/components/searchable_select";
import {getInvoices} from "@/services/invoices";
import {getSelectableClients} from "@/services/clients";
import Datatable from "@/components/datatable";
import Moment from "moment";
import {EventBus} from "@/eventbus";
import {getSelectableDrivers} from "@/services/drivers";

export default {
  name: "DefaultInvoicePage",
  components: {Datatable, SearchableSelect},
  props: {
    title: {
      type: String,
      default: 'invoices.list_of_invoices'
    },
    body: {
      type: String,
      default: 'invoices.invoices_label'
    },
    showRentalClientSelector: {
      type: [Boolean, Function],
      default: false
    },
    showDriverSelector: {
      type: [Boolean, Function],
      default: false
    }
  },
  data() {
    return {
      timeFilterId: 0,
      timeFilterOptions: [
        {id: 0, text: this.$t('common.last_x_month', {count: 3}), moment: Moment().subtract(3, 'months')},
        {id: 1, text: this.$t('common.last_year'), moment: Moment().subtract(1, 'years')},
        {id: 2, text: this.$t('common.last_x_year', {count: 3}), moment: Moment().subtract(3, 'years')},
        {id: 3, text: this.$t('common.entire_history'), moment: null},
      ],
      clientFilterId: 0,
      clientFilterOptions: () => getSelectableClients({option: 'rental'}),
      driverFilterId: 0,
      driverFilterOptions: () => getSelectableDrivers({}),
      columns: [
        {
          key: 'name',
          label: 'name',
          formatter: (value, key, item) => {
            if (this.showRentalClientSelector && item.second_client_name) {
              return value + "<br>" + item.second_client_name
            }
            if (this.showDriverSelector && item.driver_full_name) {
              return value + "<br>" + item.driver_full_name
            }
            return value
          }
        },
        {
          key: 'date',
          label: 'date',
          formatter: this.$options.filters.formatDateDay
        },
        {
          key: 'amount_taxed',
          label: 'amount',
          formatter: (value, key, item) => {
            return this.$options.filters.formatPrice(value) + this.$t('unit.euro')
          }
        },
        {key: "actions", label: 'actions'},
      ],
      actions: []
    }
  },
  watch: {
    timeFilterId: {
      immediate: false,
      handler() {
        EventBus.$emit("datatableReload")
      }
    },
    clientFilterId: {
      immediate: false,
      handler() {
        EventBus.$emit("datatableReload")
      }
    },
    driverFilterId: {
      immediate: false,
      handler() {
        EventBus.$emit("datatableReload")
      }
    }
  },
  computed: {},
  methods: {
    async fetchRecords(filterQuery) {
      // duplicating filterQuery to avoid watcher to trigger multiple times on edit
      const filterQueryDup = JSON.parse(JSON.stringify(filterQuery))
      const filterMoment = this.timeFilterOptions[this.timeFilterId].moment
      if (filterMoment) filterQueryDup.date = filterMoment.toISOString()
      else this.$delete(filterQueryDup, 'date')
      if (this.showRentalClientSelector) {
        if (this.clientFilterId > 0) filterQueryDup.second_client_id = this.clientFilterId
        else this.$delete(filterQueryDup, 'second_client_id')
      } else filterQueryDup.second_client_id = 0
      if (this.showDriverSelector) {
        if (this.driverFilterId > 0) filterQueryDup.driver_id = this.driverFilterId
        else filterQueryDup.driver_id = 0 // = 0 still searches InvoiceDriver
      } else this.$delete(filterQueryDup, 'driver_id')
      const response = await getInvoices(filterQueryDup)
      response.data.forEach((entry) => {
        entry.actions = [
          {
            label: this.$t('button.download_pdf'),
            action: (entry) => {
              window.open(entry.download_pdf_url)
            }
          },
          {
            label: this.$t('button.download_csv'),
            action: (entry) => {
              window.open(entry.download_csv_url)
            }
          }
        ]
      })
      return response
    }
  }
}
</script>